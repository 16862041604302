











































































































































































import { Component, Vue } from 'vue-property-decorator';
import 'vue-datetime/dist/vue-datetime.css';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),

  },
})
export default class ControlPanel extends Vue {
	
}
